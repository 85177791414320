import React from 'react';

import FileIcon from './code-editor/FileIcon'; // Assuming you have this component

import './FileTypeModalSelection.css';

interface FileTypeSelectionModalProps {
  onSelect: (extension: string) => void;
  onClose: () => void;
  isTeacherFile: boolean;
}


interface FileTypeSelectionModalProps {
  onSelect: (extension: string) => void;
  onClose: () => void;
}

export const FileTypeSelectionModal: React.FC<FileTypeSelectionModalProps> = ({ onSelect, onClose, isTeacherFile }) => {
  const studentFileTypes = ['py', 'js', 'txt'];
  const teacherFileTypes = ['py', 'js', 'txt', 'info', 'quiz', 'slides'];
  
  const fileTypes = isTeacherFile ? teacherFileTypes : studentFileTypes;

  const getFileName = (extension: string) => {
    switch(extension) {
      case 'py': return 'Python';
      case 'js': return 'JavaScript';
      case 'txt': return 'Text';
      case 'info': return 'Info';
      case 'quiz': return 'Quiz';
      case 'slides': return 'Slides';
      default: return extension.toUpperCase();
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>×</button>
        <h1>Select File Type</h1>
        <div className="file-type-options">
          {fileTypes.map((extension) => (
            <div key={extension} className="file-type-option" onClick={() => onSelect(extension)}>
              <div className="file-icon-container">
                <FileIcon fileName={`file.${extension}`} usage="selector" />
              </div>
              <span className="file-type-label">{getFileName(extension)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};