import React, { RefObject, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FaChevronUp, FaChevronDown, FaPlus, FaAlignJustify, FaLock, FaFolder } from 'react-icons/fa';
import { FaClipboardList, FaPeopleGroup } from 'react-icons/fa6';
import { RxEyeOpen, RxEyeClosed } from 'react-icons/rx';
import { BsBroadcast, BsClipboard2CheckFill, BsPerson } from 'react-icons/bs';
import { TbCopy, TbCopyOff } from 'react-icons/tb';

import FileUserIndicator from './FileUserIndicator';
import TimerComponent from './code-editor/TimerComponent';
import LessonDropdown from './code-editor/LessonDropdown';
import FileRenameInput from './code-editor/FileRenameInput';
import FileDropdown from './code-editor/FileDropdown';
import FileIcon from './code-editor/FileIcon';

import { getLessonString, getInitials } from './utilities';
import { CodeData, Gradebook, LessonInfo } from './types';
import { logger } from './Logger';
import GradeBox from './files-pane/GradeBox';

import './CodeEditor.css';
import './FilesPane.css';



// To note, maybe this should be split into a lesson component and a files component they are disjoint, changing between them would cause a rerender either way
// have a think about this, maybe rerender is quicker than a whole remount

interface ScrollContainerElement extends HTMLElement {
  scrollHeight: number;
}

interface CollapsedSections {
  [key: string]: boolean;
};

interface FilesPaneProps {
  codeDataRef: React.MutableRefObject<CodeData>;
  isTeacher: boolean;
  name: string;
  course: string;
  lesson: string;
  permissionedFiles: string[];
  structureVersion: number;
  lastTeacherTextUpdateTimestamp: number | null;

  gradebook: Gradebook;
  lessonsInfo: LessonInfo[];

  copyEnabled: boolean;

  onUpdateCopyEnabled: (enabled: boolean) => void;

  onUpdateGradeMax: (lesson: string, gradeMax: number | null) => void;
  onAddGradeTab: () => void;
  onAddGradebookTab: () => void;

  onSwitchLesson: (lesson: string) => void;
  onSubmitLesson: () => void;
  onCreateLesson: () => void;
  onUpdateLessonName: (lessonNameOld: string, lessonNameNew: string) => void;
  onUpdateLessonState: (lessonInfo: LessonInfo) => void;
  onUpdateFileHidden: (fileName: string, hidden: boolean) => void;
  onDeleteLesson: (lessonName: string) => void;
  
  onCreateFile: (fileType: 'student' | 'teacher', studentName?: string) => void;
  onUpdateFileName: (oldFileName: string, newFileName: string, fileType: 'student' | 'teacher', studentName?: string) => void;
  onUpdateFilePermission: (fileName: string, permission: boolean) => void;
  onUpdateFileClassOnly: (fileName: string, isClassOnly: boolean) => void;
  onDeleteFile: (fileName: string, fileType: 'student' | 'teacher', studentName?: string) => void;
  
  onAddTab: (fileName: string, fileType: 'student' | 'teacher', studentName?: string) => void;
  onSetCurrentFile: (fileName: string) => void;
}

const FilesPane: React.FC<FilesPaneProps> = React.memo(({
  codeDataRef,
  isTeacher,
  name,
  course,
  lesson,
  permissionedFiles,
  structureVersion,
  lastTeacherTextUpdateTimestamp,
  gradebook,
  lessonsInfo,
  copyEnabled,

  onUpdateCopyEnabled,

  onUpdateGradeMax,
  onAddGradeTab,
  onAddGradebookTab,

  onSwitchLesson,
  onSubmitLesson,
  onCreateLesson,
  onUpdateLessonName,
  onUpdateLessonState,
  onDeleteLesson,
  
  onCreateFile,
  onUpdateFileHidden,
  onUpdateFileName,
  onUpdateFilePermission,
  onUpdateFileClassOnly,
  onDeleteFile,

  onAddTab,
  onSetCurrentFile,
}) => {
  logger.log('Creating FilesPane, lessonsInfo: ', lessonsInfo);
  const [showLessonsDropdown, setShowLessonsDropdown] = useState(false);
  const [collapsedSections, setCollapsedSections] = useState<CollapsedSections>({ lesson: false, myFiles: false, studentFiles: false, peopleHere: true });
  const [renameLessonName, setRenameLessonName] = useState('');
  const [renameFileName, setRenameFileName] = useState('');
  const [expandedStudents, setExpandedStudents] = useState<string[]>([]);
  const secondsToShowBroadcast = 60;
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [fileAddedRemoved, setFileAddedRemoved] = useState(false);
  const containerRef: RefObject<HTMLDivElement> = useRef(null);
  const lessonSectionRef: RefObject<HTMLDivElement> = useRef(null);
  const myFilesSectionRef: RefObject<HTMLDivElement> = useRef(null);
  const studentFilesSectionRef: RefObject<HTMLDivElement> = useRef(null);
  const emptySectionRef: RefObject<HTMLDivElement> = useRef(null);
  const peopleHereSectionRef: RefObject<HTMLDivElement> = useRef(null);

  // feature flags
 
  const showFFFileUserIndicator: boolean = false;  // feature flag for file user indicators
  const showFFActivityTimer: boolean = false;  // feature flag for activity timer
  const showFFLiveCodingOnStudentFiles: boolean = false;  // feature flag for live coding on student files


  
  // below are some functions for resizing section heights
  // there are some issues, really complicated and hard to work out whats going on
  // direct DOM manipulation, is slow try to not do that, use states for the section sizes
  // this is called often will probably slow things down
  // especially on a resize might be very slow, really need a debounce for resizing
  // the resizing also happens on width resizing, should only be on height
  // consider breaking down into smaller more manageable parts
  // useEffect vs useLayoutEffect what does that mean



  // actual bugs
  // when resizing is not quite right, think maybe we added in some more margin which is messing it up
  // need to add in some thin scroll bars always visible if scrolling is needed
  // student files and my project scrollbar should be purple 


  const adjustSectionHeights = useCallback(() => {

    // this seems like quite a lot of complexity just for multiple sections that grow with content, maybe a straight split pane is better
    // this is also triggering on all updates which would be a lot of unnecessary work, must be a better way to do this
    logger.log('CE: adjustSectionHeights called');

    // trying to do refs, but I think there was a reason why I didnt do it like this before
    // anyway try it again maybe I didnt try or for some reason it works this time

    const container = containerRef.current;
    if (!container) return;

    const containerHeight = container.clientHeight;
    const containerPadding = parseFloat(getComputedStyle(container).paddingTop) + parseFloat(getComputedStyle(container).paddingBottom);
    const containerMinusPaddingHeight = containerHeight - containerPadding;

  
    const sections: Array<{ ref: RefObject<HTMLDivElement>, name: string }> = [
      { ref: lessonSectionRef, name: 'lesson' },
      { ref: myFilesSectionRef, name: 'myFiles' },
      { ref: studentFilesSectionRef, name: 'studentFiles' },
      { ref: peopleHereSectionRef, name: 'peopleHere' }
    ];
  
    let totalScrollHeight = 0;
    let totalTitlePaddingHeight = 0;
  
    // pull out the total scroll heights and also the extra height not used for scroll which is title and margin
    sections.forEach(({ ref, name }) => {
      if (ref.current) {
        const title = ref.current.querySelector('.file-name-title') as HTMLElement | null;
        const scrollContainer = ref.current.querySelector('.files-vertical-scroll') as ScrollContainerElement | null;
        const divider = ref.current.querySelector('.files-section-divider, .files-section-divider-student') as HTMLElement | null;
        
        if (title) {
          const titleHeight = title.clientHeight;
          const titleMargin = parseFloat(getComputedStyle(title).marginTop) + parseFloat(getComputedStyle(title).marginBottom);
          totalTitlePaddingHeight += titleHeight + titleMargin;
        }

        if (divider) {
          const dividerHeight = divider.clientHeight;
          const dividerMargin = parseFloat(getComputedStyle(divider).marginTop) + parseFloat(getComputedStyle(divider).marginBottom);
          totalTitlePaddingHeight += dividerHeight + dividerMargin;
        }

        const sectionPadding = parseFloat(getComputedStyle(ref.current).paddingTop) + parseFloat(getComputedStyle(ref.current).paddingBottom);
        const sectionMarginBottom = parseFloat(getComputedStyle(ref.current).marginBottom);
        totalTitlePaddingHeight += sectionPadding + sectionMarginBottom;

        if (scrollContainer && !collapsedSections[name]) {
          scrollContainer.style.height = 'auto';
          totalScrollHeight += scrollContainer.scrollHeight;
        }

        if (name === 'myFiles' && !collapsedSections[name]) {
          const submitButton = ref.current.querySelector('.submit-button') as HTMLElement | null;
          if (submitButton) {
            const submitButtonHeight = submitButton.clientHeight;
            const submitButtonMargin = parseFloat(getComputedStyle(submitButton).marginTop) + parseFloat(getComputedStyle(submitButton).marginBottom);
            logger.log('adding submit button height, margin: ', submitButtonHeight, submitButtonMargin)
            totalTitlePaddingHeight += submitButtonHeight + submitButtonMargin;
          }
        }
      }
    });
  
    // this is the height available to assign to contents via scroll sections over the sections
    const availableHeight = containerMinusPaddingHeight - totalTitlePaddingHeight;
    
  
    if (totalScrollHeight > availableHeight) {
      
      let bigScrollHeights = 0;
      let bigScrollHeightAllocation = availableHeight;

      // work out if section is big scroll or small, >25% or <25% of available height
      // small scroll elements get full allocation, big scroll elements have to proportionally share
      sections.forEach(({ ref, name }) => {
        if (ref.current) {
          const scrollContainer = ref.current.querySelector('.files-vertical-scroll') as ScrollContainerElement | null;
          if (scrollContainer && !collapsedSections[name]) {
            const proportion = scrollContainer.scrollHeight / totalScrollHeight;
            if (proportion > 0.25) {
              bigScrollHeights += scrollContainer.scrollHeight;
            } else {
              bigScrollHeightAllocation -= scrollContainer.scrollHeight;
            }
          }
        }
      });

      // set the heights of the scroll containers using their allocation
      sections.forEach(({ ref, name }) => {
        if (ref.current) {
          const scrollContainer = ref.current.querySelector('.files-vertical-scroll') as ScrollContainerElement | null;
          if (scrollContainer) {
            if (!collapsedSections[name]) {
              const proportion = scrollContainer.scrollHeight / totalScrollHeight;
              if (proportion > 0.25) {
                const bigScrollProportion = scrollContainer.scrollHeight / bigScrollHeights;
                const newScrollHeight = bigScrollHeightAllocation * bigScrollProportion;
                scrollContainer.style.height = `${newScrollHeight}px`;
              } else {
                scrollContainer.style.height = `${scrollContainer.scrollHeight}px`;
              }
            } else {
              scrollContainer.style.height = '0px';
            }
          }
        }
      });

      if (emptySectionRef.current) {
        emptySectionRef.current.style.height = '0px';
      }

    } else {
      // we have enough space for everything set all heights to auto
      if (emptySectionRef.current) {
        emptySectionRef.current.style.height = `${availableHeight - totalScrollHeight}px`;
      }

      sections.forEach(({ ref, name }) => {
        if (ref.current) {
          const scrollContainer = ref.current.querySelector('.files-vertical-scroll') as ScrollContainerElement | null;
          if (scrollContainer) {
            scrollContainer.style.height = 'auto';
          }
        }
      });
    }

  }, [collapsedSections]);

  useLayoutEffect(() => {
    logger.log('CE: useEffect(adjustSectionHeights), calling adjustSectionHeights');
    window.addEventListener('resize', adjustSectionHeights);
    adjustSectionHeights(); // Initial call

    return () => { window.removeEventListener('resize', adjustSectionHeights); };
  }, [adjustSectionHeights]);

  
  useEffect(() => {
    logger.log('CE: useEffect(fileAddedRemoved, collapsedSections, adjustSectionHeights) fileAddedRemoved changed to: ', fileAddedRemoved);
    adjustSectionHeights(); 
    setFileAddedRemoved(false);
  }, [fileAddedRemoved, collapsedSections, expandedStudents, adjustSectionHeights, lessonsInfo, showLessonsDropdown]);

  useEffect(() => {
    const checkTemplateState = () => {
      if (lessonsInfo.length === 0) {
        setIsTemplate(false);
        return;
      }

      const templateLessons = lessonsInfo.filter(lesson => lesson.state === 'template');

      if (templateLessons.length === lessonsInfo.length) {
        setIsTemplate(true);
      } else if (templateLessons.length > 0) {
        setIsTemplate(true);
        logger.warn('Some lessons are templates, but not all. This should never happen.');
      } else {
        setIsTemplate(false);
      }
    };

    checkTemplateState();
  }, [lessonsInfo]);

  

  const handleLessonIconClick = () => setShowLessonsDropdown(!showLessonsDropdown);

  const handleCopyToggle = () => {
    if (isTeacher) {
      onUpdateCopyEnabled(!copyEnabled);
    }
  };

  const toggleSection = (section: string) => {
    logger.log('FP: toggle section: ', section);
    setCollapsedSections((prevState: any) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const toggleStudentFiles = (studentName: string) => {
    setExpandedStudents((prevExpandedStudents) => {
      if (prevExpandedStudents.includes(studentName)) {
        return prevExpandedStudents.filter((name) => name !== studentName);
      } else {
        return [...prevExpandedStudents, studentName];
      }
    });
  };

   // some duplicate code, tidy this up
   const shouldShowBroadcastIcon = (fileName: string) => {
    // logger.log('FP: SSBI, fileName: ', fileName, ', lastTeacherTextUpdateTimestamp: ', lastTeacherTextUpdateTimestamp, ', codeDataRef.current.teacherTextEditFile: ', codeDataRef.current.teacherTextEditFile);
    const fullFileName = 'teacher-name-' + fileName;
    if (!lastTeacherTextUpdateTimestamp || !codeDataRef.current.teacherTextEditFile) return false;
    const elapsedSeconds = (Date.now() - lastTeacherTextUpdateTimestamp) / 1000;
    return elapsedSeconds <= secondsToShowBroadcast && fullFileName === codeDataRef.current.teacherTextEditFile;
  };
  
  const shouldShowBroadcastIconPeopleHere = (teacherName: string) => {
    // logger.log('FP: SSBIPH, teacherName: ', teacherName, ', lastTeacherTextUpdateTimestamp: ', lastTeacherTextUpdateTimestamp, ', codeDataRef.current.teacherTextEditFile: ', codeDataRef.current.teacherTextEditFile);
    if (!lastTeacherTextUpdateTimestamp || !codeDataRef.current.teacherTextEditFile) return false;
    const studentName = `student-${name}`;
    if (!(codeDataRef.current.teacherTextEditFile.startsWith('teacher-name-') || 
    (codeDataRef.current.teacherTextEditFile.startsWith(studentName) && showFFLiveCodingOnStudentFiles))) return false;
    if (teacherName !== codeDataRef.current.teacherTextEditName) return false;
    const elapsedSeconds = (Date.now() - lastTeacherTextUpdateTimestamp) / 1000;
    return elapsedSeconds <= secondsToShowBroadcast;
  };

  const shouldShowBroadcastIconStudent = (fileName: string) => {
    // logger.log('CodeEditor: SSBIS, fileName: ', fileName, ', lastTeacherTextUpdateTimestamp: ', lastTeacherTextUpdateTimestamp, ', codeDataRef.current.teacherTextEditFile: ', codeDataRef.current.teacherTextEditFile);
    if (!showFFLiveCodingOnStudentFiles) return false;
    const fullFileName = 'student-' + name + '-' + fileName;
    if (!lastTeacherTextUpdateTimestamp || !codeDataRef.current.teacherTextEditFile) return false;
    const elapsedSeconds = (Date.now() - lastTeacherTextUpdateTimestamp) / 1000;
    return elapsedSeconds <= secondsToShowBroadcast && fullFileName === codeDataRef.current.teacherTextEditFile;
  };

  
  const completeRenaming = (fileType: 'student' | 'teacher', newFileName: string, studentName?: string) => {
    logger.log('completeRenaming: fileType: ', fileType, ', renameFileName: ', renameFileName, ', newFileName: ', newFileName, ', studentName: ', studentName);
    onUpdateFileName(renameFileName, newFileName, fileType, studentName);
    setRenameFileName('');
  };

  const createTooltip = (content: string) => (props: any) => (
    <Tooltip id={`tooltip-${content.replace(/\s+/g, '-').toLowerCase()}`} {...props}>
      {content}
    </Tooltip>
  );
  


  return (
    <div className="files-pane-container">
      <div className="files-pane files-pane-font">
        <div className="files-list-top-container tab-section">
          <span className="file-name-text">{course}</span>
          <div className="files-pane-buttons">
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={
                createTooltip(
                  `Copy/Paste functionality is ${copyEnabled ? 'enabled' : 'disabled'}.` +
                  (isTeacher ? ` Press to ${copyEnabled ? 'disable' : 'enable'} copy/paste functionality.` : '')
                )
              }
            >
              <button 
                className={`copy-toggle-button ${isTeacher ? '' : 'unclickable'} ${copyEnabled ? '' : 'disabled'}`}
                onClick={handleCopyToggle}
                disabled={!isTeacher}
              >
                {copyEnabled ? <TbCopy /> : <TbCopyOff />}
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={createTooltip('Gradebook, click to open')}
            >
              <div>
                <FaClipboardList className="gradebook-icon-fp" onClick={onAddGradebookTab} />
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={createTooltip('Lessons menu')}
            >
              <div>
                <FaAlignJustify 
                  className={`lesson-dropdown-icon ${showLessonsDropdown ? 'selected' : ''}`} 
                  onClick={handleLessonIconClick} 
                />
              </div>
            </OverlayTrigger>
          </div>
        </div>
        {showLessonsDropdown ? (
          <div className="lessons-dropdown">
            {lessonsInfo
              .filter(lessonTemp => (isTeacher || lessonTemp.state !== 'hidden'))
              .map((lessonTemp) => {
                const isSelected = lessonTemp.name === lesson;
                return (
                  <div 
                    key={lessonTemp.id} 
                    className={`lesson ${lessonTemp.state} ${isTeacher ? 'teacher' : 'student'} ${isSelected ? 'selected' : ''}`} 
                    onClick={() => {
                      if ((isTeacher || lessonTemp.state !== 'locked') && !isSelected) {
                        onSwitchLesson(lessonTemp.name);
                        setShowLessonsDropdown(false);
                      }
                    }} 
                  >
                    <div className='lesson-content'>
                      <div className={`lesson-icon ${lessonTemp.state} ${isTeacher ? 'teacher' : 'student'} ${isSelected ? 'selected' : ''}`}>
                        <i className={`bi ${isSelected ? 'bi-file-fill selected' : 'bi-files'} lesson-icon`}></i>
                        <span className={`lesson-number ${lessonTemp.state} ${isTeacher ? 'teacher' : 'student'} ${isSelected ? 'selected' : ''}`}>{lessonTemp.id}</span>
                      </div>
                      {renameLessonName !== lessonTemp.name && (
                        <div className={`lesson-name ${lessonTemp.state} ${isTeacher ? 'teacher' : 'student'} ${isSelected ? 'selected' : ''}`}>
                          {getLessonString(lessonsInfo, lessonTemp.name)}
                        </div>
                      )}
                      <div className="lesson-ellipsis-section margin-left-auto">
                        {lessonTemp.state === 'locked' && renameLessonName !== lessonTemp.name && (
                          <FaLock className='lock-icon'/>
                        )}
                        {renameLessonName !== lessonTemp.name && (
                          <div className="margin-left-05 margin-right-05">
                            <GradeBox 
                              submitted={false}
                              grade={null}
                              gradeMax={lessonsInfo.filter((lessonInfo) => lessonInfo.name === lessonTemp.name)[0].gradeMax}
                              isTeacher={isTeacher}
                              onAddGradeTab={onAddGradeTab}
                              isGradeMax={true}
                              isGradebook={true}
                              onUpdateGradeMax={(gradeMax: number | null) => {onUpdateGradeMax(lessonTemp.name, gradeMax);}}
                            />
                          </div>
                        )}
                        {isTeacher && !isTemplate && (
                          renameLessonName === lessonTemp.name ? (
                            <FileRenameInput
                              initialFileName={lessonTemp.name}
                              onRenameComplete={(newLessonName) => {
                                onUpdateLessonName(lessonTemp.name, newLessonName);
                                setRenameLessonName('');
                              }}
                              onCancel={() => setRenameLessonName('')}
                            />
                          ) : (
                            <LessonDropdown
                              lesson={lessonTemp}
                              onEditName={() => setRenameLessonName(lessonTemp.name)}
                              onDelete={onDeleteLesson}
                              onStateChange={onUpdateLessonState}
                              isDeletable={lessonsInfo.length > 1}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            }
            {isTeacher && !isTemplate && (
              <div className="add-lesson" onClick={onCreateLesson}>
                <FaPlus className="add-lesson-icon" />
                <div className="add-lesson-text">Add Lesson</div>
              </div>
            )}
          </div>
        ) : (
          <div className="files-list-container-container" ref={containerRef}>
            <div className="files-list-container margin-bottom-1" ref={lessonSectionRef} >
              <div className={`file-name-container-title file-name-title ${collapsedSections.lesson ? '' : 'file-name-margin'}`} id="lesson">
                {collapsedSections.lesson ? (
                  <FaChevronUp className="chevron-icon" onClick={() => toggleSection('lesson')} />
                ) : (
                  <FaChevronDown className="chevron-icon" onClick={() => toggleSection('lesson')} />
                )}
                <span className="lesson-name">
                  {getLessonString(lessonsInfo, lesson)}
                </span>
                {isTeacher && !isTemplate && <FaPlus className="add-file-icon" onClick={() => {onCreateFile('teacher');}} />}
              </div>
              {!collapsedSections.lesson && (<div className="files-section-divider"></div>)}
              {!collapsedSections.lesson && (
                <div className="files-vertical-scroll custom-scrollbar">
                  {Object.keys(codeDataRef.current.teacherFiles ?? {})
                    .sort((a, b) => {
                      const extA = a.split('.').pop();
                      const extB = b.split('.').pop();
                      if (extA === 'py' && extB !== 'py') return 1;
                      if (extA !== 'py' && extB === 'py') return -1;
                      return 0;
                    })
                    .filter(fileName => (isTeacher || !codeDataRef.current.teacherFiles?.[fileName]?.hidden))
                    .map((fileName) => {
                      
                    const isPermissioned = permissionedFiles.includes(fileName);
                    const isClassOnly = codeDataRef.current.teacherFiles?.[fileName]?.classOnly ?? true;
                    return (
                      <div key={fileName} >
                        {(isTeacher && renameFileName === fileName) ? (
                          <FileRenameInput
                            initialFileName={fileName}
                            onRenameComplete={(newFileName) => {
                              completeRenaming('teacher', newFileName);
                            }}
                            onCancel={() => setRenameFileName('')}
                          />
                        ) : (
                          <div className="file-item-content" >
                            <div className="file-name-container">
                              <span className="file-name" style={{ opacity: codeDataRef.current.teacherFiles?.[fileName]?.hidden ? 0.7 : 1 }} onClick={() => {
                                onAddTab(fileName, 'teacher');
                              }}>
                                <FileIcon fileName={fileName} usage={'pane'} />
                                <span className={`file-name-text ${(isClassOnly && isTeacher) ? 'pink' : ''}`}>{fileName}</span>
                              </span>
                              <div className="flex-div">
                                {showFFFileUserIndicator && (
                                  <FileUserIndicator codeData={codeDataRef.current} fileName={fileName} />
                                )}
                                {shouldShowBroadcastIcon(fileName) && (
                                  <BsBroadcast className="broadcast-icon"/>
                                )}
                                {(isTeacher || isPermissioned) && (
                                  <div 
                                    className={`permission-icon ${isPermissioned ? 'permissioned' : 'not-permissioned'}`}
                                    onClick={() => isTeacher && onUpdateFilePermission(fileName, !isPermissioned)}
                                    style={{ cursor: isTeacher ? 'pointer' : 'default' }}
                                  >
                                    <FaPeopleGroup />
                                  </div>
                                )}
                                {isTeacher && (
                                  <div 
                                    className="eye-icon" 
                                    onClick={() => onUpdateFileHidden(fileName, !codeDataRef.current.teacherFiles?.[fileName]?.hidden)}
                                  >
                                    {codeDataRef.current.teacherFiles?.[fileName]?.hidden ? <RxEyeClosed /> : <RxEyeOpen />}
                                  </div>
                                )}
                                {isTeacher && !isTemplate && (
                                  <FileDropdown
                                    isHorizontalDots={true}
                                    fileName={fileName}
                                    allowModifyFileName={true}
                                    allowDeleteFile={true}
                                    isClassOnly={isClassOnly}
                                    allowChangeClassOnly={true}
                                    onRenameClick={(fileNameInternal) => {
                                      logger.log('editing fileName: ', fileNameInternal);
                                      setRenameFileName(fileNameInternal);
                                    }}
                                    onDeleteClick={(fileNameInternal) => onDeleteFile(fileNameInternal, 'teacher', name)}
                                    onChangeClassOnly={(fileNameInternal, isClassOnlyInternal) => onUpdateFileClassOnly(fileNameInternal, isClassOnlyInternal)}
                                  />  
                                )}
                              </div>    
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            {!isTeacher && (
              <div className="files-list-container dark-purple margin-bottom-1" ref={myFilesSectionRef}>
                <div className={`file-name-container-title file-name-title ${collapsedSections.myFiles ? '' : 'file-name-margin'}`} id="myFiles">
                  {collapsedSections.myFiles ? (
                    <FaChevronUp className="chevron-icon" onClick={() => toggleSection('myFiles')} />
                  ) : (
                    <FaChevronDown className="chevron-icon" onClick={() => toggleSection('myFiles')} />
                  )}
                  <span className='lesson-name'>
                    My Project
                  </span>
                  <div className="margin-left-auto">
                    <GradeBox 
                      submitted={gradebook[name][lesson].submitted}
                      grade={gradebook[name][lesson].grade}
                      gradeMax={lessonsInfo.filter((lessonInfo) => lessonInfo.name === lesson)[0].gradeMax}
                      isTeacher={false}
                      onAddGradeTab={onAddGradeTab}
                    />
                  </div>
                  <FaPlus className="add-file-icon-myproject" onClick={() => onCreateFile('student', name)} />
                </div>
                {!collapsedSections.myFiles && (
                  <>
                    <div className="files-section-divider-student"></div>
                    <div className="files-vertical-scroll custom-scrollbar purple-scrollbar" key={structureVersion}>
                      {Object.keys(codeDataRef.current.studentFiles?.[name] ?? {}).map((fileName) => (
                        <div key={fileName} >
                          {renameFileName === fileName ? (
                            <FileRenameInput
                              initialFileName={fileName}
                              onRenameComplete={(newFileName) => {
                                logger.log('callback onRenameComplete, newFileName: ', newFileName);
                                completeRenaming('student', newFileName, name);
                              }}
                              onCancel={() => setRenameFileName('')}
                            />
                          ) : (
                            <div className="file-item-content">
                              <div className="file-name-container">
                                <span className="file-name" onClick={(e) => {
                                  e.stopPropagation();
                                  logger.log('clicked on fileName: ', fileName);
                                  onAddTab(fileName, 'student');
                                }}>
                                  <FileIcon fileName={fileName} usage={'pane'} />
                                  <span className="file-name-text">{fileName}</span>
                                </span>
                                <div className="file-right-icons">
                                  {showFFFileUserIndicator && (
                                    <FileUserIndicator codeData={codeDataRef.current} fileName={fileName} studentName={name} />
                                  )}
                                  {shouldShowBroadcastIconStudent(fileName) && (
                                    <BsBroadcast className="broadcast-icon margin-right-small"/>
                                  )}
                                  <FileDropdown
                                    isHorizontalDots={true}
                                    fileName={fileName}
                                    allowModifyFileName={true}
                                    allowDeleteFile={true}
                                    isClassOnly={true}
                                    allowChangeClassOnly={false}
                                    onRenameClick={(fileName) => {
                                      logger.log('editing fileName: ', fileName);
                                      setRenameFileName(fileName);
                                    }}
                                    onDeleteClick={(fileName) => onDeleteFile(fileName, 'student', name)}
                                    onChangeClassOnly={() => {}}
                                  />   
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="submit-button-container">
                      <button className="btn btn-purple submit-button" onClick={onSubmitLesson}>
                        <span>
                          <BsClipboard2CheckFill className="submit-button-icon" />
                          <span className="submit-button-text">
                            {
                              gradebook[name][lesson].submitted ? 
                                (lessonsInfo.filter((lessonInfo) => lessonInfo.name === lesson)[0].gradeMax === null ? 'Uncomplete' : 'Unsubmit') :
                                (lessonsInfo.filter((lessonInfo) => lessonInfo.name === lesson)[0].gradeMax === null ? 'Complete' : 'Submit')
                            }
                          </span>
                        </span>  
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
            {isTeacher && (
              <div className="files-list-container dark-purple margin-bottom-1" ref={studentFilesSectionRef}>
                <div className={`file-name-container-title file-name-title ${collapsedSections.studentFiles ? '' : 'file-name-margin'}`} id="studentFiles">
                  {collapsedSections.studentFiles ? (
                    <FaChevronUp className="chevron-icon" onClick={() => toggleSection('studentFiles')} />
                  ) : (
                    <FaChevronDown className="chevron-icon" onClick={() => toggleSection('studentFiles')} />
                  )}
                  <span className='lesson-name'>
                    Student files
                  </span>
                  <span className="margin-left-auto">
                    <GradeBox 
                      submitted={false}
                      grade={null}
                      gradeMax={lessonsInfo.filter((lessonInfo) => lessonInfo.name === lesson)[0].gradeMax}
                      isTeacher={isTeacher}
                      onAddGradeTab={onAddGradeTab}
                      isGradeMax={true}
                      onUpdateGradeMax={(gradeMax: number | null) => {onUpdateGradeMax(lesson, gradeMax);}}
                    />
                  </span>
                </div>
                {!collapsedSections.studentFiles && (
                  <>
                    <div className="files-section-divider-student"></div>
                    <div className="files-vertical-scroll custom-scrollbar purple-scrollbar">
                      {Object.keys(codeDataRef.current.studentFiles ?? {}).map((studentName) => {
                        const isConnected = codeDataRef.current.studentConnections?.includes(studentName);

                        return (
                          <div key={studentName} className={`student-folder mb-2 ${isConnected ? '' : 'student-disconnected'}`}>
                            <div className="student-folder-header mb-2">
                              <div className="student-folder-title" onClick={() => toggleStudentFiles(studentName)}>
                                <FaFolder className="folder-icon" />
                                <span className="student-name lesson-name">
                                  {studentName}
                                  {showFFActivityTimer && codeDataRef.current.lastEditTimes?.[studentName] && (
                                    <TimerComponent
                                      lastEditTime={codeDataRef.current.lastEditTimes[studentName]}
                                      currentTimestampFE={codeDataRef.current.currentTimestampFE}
                                      currentTimestampBE={codeDataRef.current.currentTimestampBE}
                                    />
                                  )}
                                </span>
                              </div>
                              <div className="student-folder-actions">
                                <FaPlus
                                  className="add-file-icon add-file-icon-rm"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    onCreateFile('student', studentName);
                                  }}
                                />
                                {expandedStudents.includes(studentName) ? (
                                  <FaChevronDown className="collapse-icon collapse-margin-right" onClick={() => toggleStudentFiles(studentName)} />
                                ) : (
                                  <FaChevronUp className="collapse-icon collapse-margin-right" onClick={() => toggleStudentFiles(studentName)} />
                                )}
                              </div>
                            </div>
                            {expandedStudents.includes(studentName) && (
                              <div className="student-files mb-2">
                                {codeDataRef.current.studentFiles && Object.keys(codeDataRef.current.studentFiles[studentName] ?? {}).map((fileName) => (
                                  <div key={fileName} className="file-item-content mb-2">
                                    <div className="file-name-container">
                                      <span className="file-name" onClick={() => {
                                        onAddTab(fileName, 'student', studentName);
                                        onSetCurrentFile(`student-${studentName}-${fileName}`);
                                      }}>
                                        <FileIcon fileName={fileName} usage={'pane'} />
                                        <span className="file-name-text">{fileName}</span>
                                      </span>
                                      {showFFFileUserIndicator && (
                                        <FileUserIndicator codeData={codeDataRef.current} fileName={fileName} studentName={studentName} />
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
            <div className="files-list-container-empty" ref={emptySectionRef}></div>
            <div className="files-list-container" ref={peopleHereSectionRef}>
              <div className={`file-name-container-title file-name-title ${collapsedSections.peopleHere ? '' : 'file-name-margin'} d-flex justify-content-between align-items-center`} id="peopleHere">
                {collapsedSections.peopleHere ? (
                  <FaChevronUp className="chevron-icon" onClick={() => toggleSection('peopleHere')} />
                ) : (
                  <FaChevronDown className="chevron-icon" onClick={() => toggleSection('peopleHere')} />
                )}
                <span className="lesson-name">People here</span>
                <span className="people-count">
                  {(codeDataRef.current.teacherConnections?.length || 0) + (codeDataRef.current.studentConnections?.length || 0)}
                </span>
              </div>
              {!collapsedSections.peopleHere && (<div className="files-section-divider"></div>)}
              {!collapsedSections.peopleHere && (
                <div className="files-vertical-scroll custom-scrollbar">
                  {codeDataRef.current.teacherConnections && codeDataRef.current.teacherConnections.map((teacherName) => (
                    <div key={teacherName} className="student-item mb-2">
                      <div className="people-here-left-part">
                        <span className="people-here-initials-circle teacher">{getInitials(teacherName)}</span>
                        <span className="people-here-name lesson-name">{teacherName}</span>
                      </div>
                      <div className="people-here-right-icon">
                        {shouldShowBroadcastIconPeopleHere(teacherName) && (
                          <BsBroadcast className="broadcast-icon"/>
                        )}
                      </div>
                    </div>
                  ))}
                  {codeDataRef.current.studentConnections && codeDataRef.current.studentConnections.map((studentName) => (
                    <div key={studentName} className="student-item mb-2">
                      <div className="people-here-initials-circle student">{getInitials(studentName)}</div>
                      <span className="student-name lesson-name">{studentName}</span>
                    </div>
                  ))}
                  {codeDataRef.current.allStudents.filter(
                    (studentName) => !codeDataRef.current.studentConnections?.includes(studentName)
                  ).map((disconnectedStudent) => (
                    <div key={disconnectedStudent} className="student-item mb-2 disconnected-student">
                      <div className="people-here-left-part">
                        <span className="people-here-initials-circle student" style={{ opacity: 0.7 }}>
                          {getInitials(disconnectedStudent)}
                        </span>
                        <span className="people-here-name lesson-name" style={{ opacity: 0.7 }}>
                          {disconnectedStudent}
                        </span>
                      </div>
                      <div className="people-here-right-icon">
                        <BsPerson className="empty-person-icon" />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default FilesPane;