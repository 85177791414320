import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Nav } from 'react-bootstrap';
import { BsArrowCounterclockwise, BsBoxArrowUpRight, BsBroadcast, BsPlusCircle, BsFillPlayFill } from 'react-icons/bs';

import { isTabCode, getFileNameFromTabName, getFileTypeFromTabName, getNameFromTabName, getInitials } from '../utilities';

import FileDropdown from './FileDropdown';
import FileIcon from './FileIcon';
import { logger } from '../Logger';

import './DraggableTabs.css';



// shouldShowBroadcastIconFile, calling a callback to get a boolean check seems wrong, look to change this

interface DraggableTabsProps {
  paneId: string;
  isTeacher: boolean;
  tabs: string[];
  currentTab: string | null;
  isTemplate: boolean;
  onRemoveTab: (tabId: string) => void;
  onAddTabRef: (tabId: string, element: HTMLDivElement | null) => void;
  onShouldShowBroadcastIconFile: (tabId: string) => boolean;
  // onAddFile: (isTeacherFile: boolean, name: string, fileName: string, text: string) => void;  // add this later maybe, should not have params
  onRunCode: () => void;
  onRevertChanges: () => void;
  onRemoveFile: (fileName: string, fileType: 'student' | 'teacher', studentName?: string) => void;
}

const DraggableTabs: React.FC<DraggableTabsProps> = ({
  paneId,
  isTeacher,
  tabs,
  currentTab,
  isTemplate,
  onRemoveTab,
  onAddTabRef,
  onShouldShowBroadcastIconFile,
  // onAddFile,
  onRunCode,
  onRevertChanges,
  onRemoveFile,
}) => {
  const fileName = getFileNameFromTabName(currentTab);
  const fileType = getFileTypeFromTabName(currentTab);
  const name = getNameFromTabName(currentTab);

  const removeFile = () => {
    if (fileType === 'student') {
      onRemoveFile(fileName, fileType, name);
    } else if (fileType === 'teacher') {
      onRemoveFile(fileName, fileType);
    } else {
      logger.error('removeFile called with invalid fileType', fileType);
    }
  }

  return (
    <Droppable droppableId={paneId} direction="horizontal">
      {(provided) => (
        <Nav 
          variant="tabs" 
          {...provided.droppableProps} 
          ref={provided.innerRef}
          className="draggable-tabs-container"
        >
          {tabs.map((tabId, index) => {
            const [fileType, ...rest] = tabId.split('-');
            const fileName = rest.pop() ?? '';
            const isTeacherFile = fileType === 'teacher';
            const studentName = rest.join('-');

            return (
              <Draggable key={tabId} draggableId={tabId} index={index}>
                {(provided, snapshot) => (
                  <Nav.Item
                    ref={(element: any) => {
                      provided.innerRef(element);
                      onAddTabRef(tabId, element);
                    }}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      userSelect: 'none',
                      background: snapshot.isDragging ? '#f4f4f4' : 'inherit',
                    }}
                    className="tab-name"
                  >
                    <Nav.Link eventKey={tabId} className={`files-pane-font tab-name-inside ${isTeacherFile ? 'teacher-file-tab tab-section' : 'student-file-tab tab-section'} ${paneId ==='outputPane' ? 'output' : 'main'}`}>
                      {isTeacherFile ? (
                        <div className="icon-margin-right">
                          <FileIcon fileName={fileName} usage={'tab'} />
                        </div>
                      ) : (
                        <div className="initials-circle student">{getInitials(studentName)}</div>
                      )}
                      <span className="tab-name-text">{fileName}</span>
                      {onShouldShowBroadcastIconFile(tabId) && <BsBroadcast className="broadcast-icon" />}
                      <i
                        className="bi bi-x"
                        onClick={(e) => {
                          e.stopPropagation();
                          onRemoveTab(tabId);
                        }}
                      ></i>
                    </Nav.Link>
                  </Nav.Item>
                )}
                
              </Draggable>
            );
          })}
          {provided.placeholder}
          <div className="right-aligned-elements">
            {isTabCode(currentTab) && (
              <>
                <button className="circular-button green-button" onClick={() => onRunCode()}>
                  <BsFillPlayFill />
                </button>
                {/* <button className="circular-button gray-button" onClick={onRevertChanges}>
                  <BsArrowCounterclockwise />
                </button> // add this back when we put back revert changes*/}
                {((!isTeacher && fileType === 'student') || (isTeacher && fileType === 'teacher' && !isTemplate)) && (
                  <FileDropdown
                    isHorizontalDots={false}
                    fileName={getFileNameFromTabName(currentTab)}
                    allowModifyFileName={false}
                    allowDeleteFile={true}
                    isClassOnly={false}
                    allowChangeClassOnly={false}
                    onRenameClick={(_: string) => {}}
                    onDeleteClick={() => removeFile()}
                    onChangeClassOnly={() => {}}
                  /> 
                )}
              </>
            )}
            {paneId ==='outputPane' && (
              <Nav.Item className='console-tab'>
                <Nav.Link className='student-file-tab tab-section files-pane-font output' eventKey="console">
                  Console
                  <div className="popout-icon">
                    <BsBoxArrowUpRight />
                  </div>
                </Nav.Link>
              </Nav.Item>
            )}
          </div>
        </Nav>
      )}
    </Droppable>
  );
};

export default DraggableTabs;