import React, { useState, useEffect } from 'react';
import './GradeFile.css';

interface GradeFileProps {
  name: string;
  lessonName: string;
  grade: number | null;
  gradeMax: number;
  feedback: string;
  isTeacher: boolean;
  onUpdateGradeAndFeedback: (grade: number | null, feedback: string) => void;
}

const GradeFile: React.FC<GradeFileProps> = ({
  name,
  lessonName,
  grade,
  gradeMax,
  feedback,
  isTeacher,
  onUpdateGradeAndFeedback,
}) => {
  const [editedGrade, setEditedGrade] = useState<string>(grade !== null ? grade.toString() : '');
  const [editedFeedback, setEditedFeedback] = useState(feedback);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setEditedGrade(grade !== null ? grade.toString() : '');
    setEditedFeedback(feedback);
  }, [grade, feedback]);

  const handleSubmit = () => {
    const newGrade = editedGrade === '' ? null : Number(editedGrade);
    onUpdateGradeAndFeedback(newGrade, editedFeedback);
    setIsEditing(false);
  };

  const handleRevert = () => {
    setEditedGrade(grade !== null ? grade.toString() : '');
    setEditedFeedback(feedback);
    setIsEditing(false);
  };

  return (
    <div className="grade-file">
      <h2 className="grade-file-title">
        {name} - Grading and Feedback for Lesson {lessonName}
      </h2>
      <div className="grade-section">
        <label htmlFor="grade-input">Grade:</label>
        <input
          id="grade-input"
          type="text"
          value={isTeacher ? editedGrade : (grade !== null ? grade : 'Not graded')}
          onChange={(e) => {
            setEditedGrade(e.target.value);
            setIsEditing(true);
          }}
          readOnly={!isTeacher}
          className="grade-input"
        />
        <span className="grade-max">/ {gradeMax}</span>
      </div>
      <div className="feedback-section">
        <label htmlFor="feedback-area">Feedback:</label>
        <textarea
          id="feedback-area"
          value={isTeacher ? editedFeedback : feedback}
          onChange={(e) => {
            setEditedFeedback(e.target.value);
            setIsEditing(true);
          }}
          readOnly={!isTeacher}
          className="feedback-area"
        />
      </div>
      {isTeacher && (
        <div className="button-section">
          <button 
            onClick={handleSubmit} 
            disabled={!isEditing}
            className="grade-submit-button"
          >
            Submit Changes
          </button>
          <button 
            onClick={handleRevert} 
            disabled={!isEditing}
            className="revert-button"
          >
            Revert Changes
          </button>
        </div>
      )}
    </div>
  );
};

export default GradeFile;