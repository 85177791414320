import React from 'react';

import { BsQuestionSquare } from "react-icons/bs";
import { RiSlideshow2Fill, RiFileInfoFill, RiFileTextFill } from "react-icons/ri";
import { SiKahoot } from "react-icons/si";

import './FileIcon.css'
import { FaClipboardList } from 'react-icons/fa6';

// could this be optimised, 

const images = {
  python: { name: "python-logo", image: `${process.env.PUBLIC_URL}/Images/python-logo-only.png` }, 
  js: { name: "js-logo", image: `${process.env.PUBLIC_URL}/Images/JavaScript-logo.png` },
};

interface FileIconProps {
  fileName: string;
  usage: 'pane' | 'tab' | 'selector';
}

const FileIcon: React.FC<FileIconProps> = React.memo(({ fileName, usage }) => {
  const extension = fileName.split('.').pop() || '';
  const sizeStyle = 'icon-size-' + usage;

  switch (extension) {
    case 'py':
      return <img src={images.python.image} alt={images.python.name} className={`python-logo ${sizeStyle}`} />;
    case 'js':
      return <img src={images.js.image} alt={images.js.name} className={`js-logo ${sizeStyle}`} />;
    case 'old-txt':
      return <i className={`fas fa-file-alt file-icon ${sizeStyle}`} />;
    case 'txt':
      return <RiFileTextFill className={`text-file-icon ${sizeStyle}`} />;
    case 'info':
      return <RiFileInfoFill className={`info-file-icon ${sizeStyle}`} />;
    case 'slides':
      return <RiSlideshow2Fill className={`slides-icon ${sizeStyle}`} />;
    case 'quiz':
      return <SiKahoot className={`quiz-icon ${sizeStyle}`} />;
    case 'gb':
      return <FaClipboardList className={`gradebook-icon ${sizeStyle}`} />;
    default:
      return <BsQuestionSquare className={`question-icon ${sizeStyle}`} />;
  }
});

export default FileIcon;