import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import { BsLockFill, BsEyeSlashFill, BsDoorClosedFill, BsDoorOpenFill, BsFileEarmarkText } from 'react-icons/bs';

import GradeBox from '../files-pane/GradeBox';  // Assuming you have this component
import { Gradebook, LessonInfo } from '../types';

import './GradebookFile.css';  // You'll need to create this CSS file
import { logger } from '../Logger';


interface GradebookFileProps {
  gradebook: Gradebook;
  lessonsInfo: LessonInfo[];
  onAddGradeTab: (student_name: string, lesson_name: string) => void;
  onUpdateGradeMax: (lesson_name: string, gradeMax: number | null) => void
}

const GradebookFile: React.FC<GradebookFileProps> = ({ gradebook, lessonsInfo, onAddGradeTab, onUpdateGradeMax }) => {
  const students = Object.keys(gradebook);

  const getTotalGrade = (studentName: string) => {
    return Object.values(gradebook[studentName]).reduce((total, lesson) => total + (lesson.grade?? 0), 0);
  };

  const getTotalGradeMax = () => {
    return Object.values(lessonsInfo).reduce((total, lessonInfo) => (total?? 0) + (lessonInfo.gradeMax?? 0), 0);
  };

  return (
    <Container fluid className="gradebook-file">
      <Row className="lesson-names">
        <Col className="equal-width-col"></Col>
        {lessonsInfo.map((lesson, index) => (
          <Col className="equal-width-col" key={lesson.id}>
            <div className="lesson-name-text">
              {lesson.name}
            </div>
          </Col>
        ))}
        <Col className="equal-width-col">Totals</Col>
      </Row>
      <Row className="grade-maxes">
        <Col className="equal-width-col">Grade Max</Col>
        {lessonsInfo.map((lesson) => (
          <Col className="equal-width-col" key={lesson.id}>
            <GradeBox
              submitted={true}
              grade={null}
              gradeMax={lessonsInfo.filter((lessonInfo) => lessonInfo.name === lesson.name)[0].gradeMax}
              isTeacher={true}
              isGradeMax={true}
              isGradebook={true}
              onUpdateGradeMax={(gradeMax: number | null) => { onUpdateGradeMax(lesson.name, gradeMax);}}
            />
          </Col>
        ))}
        <Col className="equal-width-col">{getTotalGradeMax()}</Col>
      </Row>
      {students.map((student) => (
        <Row key={student} className="student-row">
          <Col className="equal-width-col student-name">{student}</Col>
          {lessonsInfo.map((lesson) => (
            <Col className="equal-width-col" key={lesson.id}>
              <GradeBox
                submitted={gradebook[student][lesson.name].submitted}
                grade={gradebook[student][lesson.name].grade}
                gradeMax={lessonsInfo.filter((lessonInfo) => lessonInfo.name === lesson.name)[0].gradeMax}
                isTeacher={true}
                isGradebook={true}
                onAddGradeTab={() => {
                  logger.log('Gradebook calling onAddGradeTab with student: ', student);
                  onAddGradeTab(student, lesson.name);
                }}
              />
            </Col>
          ))}
          <Col className="equal-width-col">{getTotalGrade(student)} / {getTotalGradeMax()}</Col>
        </Row>
      ))}
    </Container>
  );
};

export default GradebookFile;