export interface Course {
  id: number;
  name: string;
  lessonCount: number;
  description: string;
  state: string;
}

export interface LessonInfo {
  id: number;
  name: string;
  state: 'open' | 'closed' | 'locked' | 'hidden' | 'template';
  gradeMax: number | null;
}

export interface GradeEntry {
  submitted: boolean;
  grade: number | null;
  feedback: string;
}

export interface Gradebook {
  [studentName: string]: {
    [lessonName: string]: GradeEntry;
  };
}

export interface CodeData {
  // studentFiles, teacherFiles, studentConnections, teacherConnections are set to null
  // this is a preloaded state, prevent rendering CodeEditor unless this data is loaded and therefore not null
  studentFiles: { [studentName: string]: { [fileName: string]: { text: string, newFile: boolean } } } | null;
  teacherFiles: { [fileName: string]: { text: string, hidden: boolean, classOnly: boolean } } | null;
  // To note: On changeType and changeOrigin, these were needed very much for the appContext paradigm to see where stuff came from
  // now we are sending through updates up and down the stack via props and callbacks that is not needed so much
  // I think we can strap the changeOrigin and just use changeType, the important types are
  // 'initialUpdate' opens a first tab
  // 'studentUpdatesSelf' update from a student of their own file
  // 'studentUpdatesTeacher' update from a student of a class file
  // 'liveCoding' teacher updating a class file
  // 'teacherUpdatesStudent' teacher updating a student file, we keep the live coding icons but do not do the live coding alert
  // 'currentFile' if the current file changes
  // 'structureUpdate' for lesson CRUD or file CRUD, no text update
  // 'teacherUpdateStudent' this is different from live update as 
  // null used for stealth updates
  changeType: 'selfUpdate' | 'studentUpdatesSelf' | 'studentUpdatesTeacher' | 'liveCoding' | 'teacherUpdatesStudent' | 'initialUpdate' | 'currentFile' | 'structureUpdate' | null;
  changeName: string | null;  // the name of the user that changed the file, used for seeing the name tag for typing
  changeFile: string | null;
  teacherTextEditFile: string | null;
  teacherTextEditName: string | null;
  userInfo: {
    [userName: string]: {
      userType: 'student' | 'teacher';
      caretPosition: { line: number; col: number };
      highlightedRange: { anchor: { line: number; col: number }; head: { line: number; col: number } };
      activeFile: string | null;
    };
  };
  lastEditTimes: { [fileName: string]: number };
  currentTimestampBE: number;
  currentTimestampFE: number;
  studentConnections: string[] | null,
  teacherConnections: string[] | null,
  allStudents: string[]
  // lessonsInfo: LessonInfo[]; I have taken this out of the of CodeData, the idea is to take out structural change stuff from the ref
  // they can be instead handled at state variables which is much more obvious and clearer
  // the purpose of CodeData is to handle frequently updated data that we want to avoid rerenders on, such as typing and clicking around
  // the ref also allows for partial updates which the state doesnt, that is fine if the structural updates are infrequent and are not huge objects
  version: number;
}

export const initialCodeData: CodeData = {
  teacherFiles: {},
  studentFiles: {},
  changeType: null,
  changeName: null,
  changeFile: null,
  teacherTextEditFile: null,
  teacherTextEditName: null,
  userInfo: {},
  lastEditTimes: {},
  currentTimestampBE: 0,
  currentTimestampFE: 0,
  studentConnections: [],
  teacherConnections: [],
  allStudents: [],
  version: 0,
};

export const defaultStealthModeUserInfo = {  // add a default so we dont see carats
  userType: 'teacher',
  caretPosition: { line: 1, col: 1 },
  highlightedRange: { anchor: { line: 1, col: 1 }, head: { line: 1, col: 1 } },
  activeFile: 'stealth_mode.py'
};
  
export interface UserInfo {
  userType: 'student' | 'teacher';
  caretPosition: { line: number; col: number };
  highlightedRange: { anchor: { line: number; col: number }; head: { line: number; col: number } };
  activeFile: string | null;
};

export interface ColorScheme {
  background: string;
  foreground: string;
  mainPurple: string;
  shades: string[];
  purpleShades: string[];
}

export type ColorSchemes = {
  [key: string]: ColorScheme;
};